// import { ChainId } from '@pancakeswap-libs/sdk';
import { ChainId } from '@traderjoe-xyz/sdk';
import ERC20 from './ethereum/ERC20/ERC20';
import { Symbol } from './types';

import usdc from "./assets/images/koc/usdc.svg";
import avax from "./assets/images/koc/avax.svg";
import slot from "./assets/images/koc/SLOT.svg";
import stomb from "./assets/images/koc/STOMB.svg";
import grave from "./assets/images/koc/grave.svg";
import gshare from "./assets/images/koc/gshare.svg";
import zombie from "./assets/images/koc/zombie.svg";
import zshare from "./assets/images/koc/zshare.svg";
import glad from "./assets/images/koc/glad.svg";
import gladshare from "./assets/images/koc/gladshare.svg";
import wlrs from "./assets/images/koc/wlrs.svg";
import wshare from "./assets/images/koc/wshare.svg";
import grape from "./assets/images/koc/grape.svg";
import wine from "./assets/images/koc/wine.svg";
import astro from "./assets/images/koc/astro.png";
import game from "./assets/images/koc/game.jpg";
import serene from "./assets/images/koc/serene.svg";
import magik from "./assets/images/koc/magik.png";
import btc from "./assets/images/koc/btc.png";
import eth from "./assets/images/koc/eth.png";

let branchConfs = {
  'release': "production",
  'release-test': "production",
  'release-dev': "development",
  'beta-release': "development",
  'beta-release-test': "development",
  'beta-release-dev': "development",
}

export const selectedConf: string = branchConfs[process.env.REACT_APP_CF_PAGES_BRANCH] || process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV || 'development';

export type Configuration = {
  name: string,
  chainId: ChainId;
  forceDisableGenesis?: boolean;
  tokens: { [contractName: string]: { address: string, decimals: number, displayName?: string } };
  contracts: { [contractName: string]: string };
  koc: { [contractName: string]: string };
  genesisPools: { [contractName: string]: string };
  graphUrls: { [name: string]: string };
  preyPredator: string;
  preyPredatorGen0Mint: string; 
  NFTMintRegister: string;
  NFTPools: { [name: string]: string };
  NFTTrainer: string;
  evolution: string;
};

const configurations: { [env: string]: Configuration } = {
  development: {
    name: 'development',
    chainId: ChainId.FUJI,
    forceDisableGenesis: false,
    tokens: {
      kocUSDC: { address: '0x3f8896C313B276D412856Ff67bb2842FBb8Ac134', decimals: 18 },
      AVAX: { address: "0xd00ae08403B9bbb9124bB305C09058E32C39A48c", decimals: 18 },
      WAVAX: { address: "0xd00ae08403B9bbb9124bB305C09058E32C39A48c", decimals: 18 },
      USDC: { address: "0x9f46dDE750F45be77DdFec8D242d803F1a017CE2", decimals: 18 },
      STOMB: { address: "0x9e6832D13b29d0B1C1c3465242681039b31C7a05", decimals: 18 },
      SLOT: { address: "0x924157B5dbB387A823719916B25256410a4Ad470", decimals: 18 },
      GRAVE: { address: "0x3700a92dd231F0CaC37D31dBcF4c0f5cCb1db6Ca", decimals: 18 },
      GSHARE: { address: "0xFfE04Bf98C7111360Bf7A6c56b343915543cD941", decimals: 18 },
      ZOMBIE: { address: "0x431bDC9975D570da5eD69C4E97e27114BCd55a86", decimals: 18 },
      ZSHARE: { address: "0xF05e236A139CB19851cD5568A85094D6EE331fAc", decimals: 18 },
      WLRS: { address: "0x395908aeb53d33A9B8ac35e148E9805D34A555D3", decimals: 18 },
      WSHARE: { address: "0xe6d1aFea0B76C8f51024683DD27FA446dDAF34B6", decimals: 18 },
      GRAPE: { address: "0x5541D83EFaD1f281571B343977648B75d95cdAC2", decimals: 18 },
      WINE: { address: "0xC55036B5348CfB45a932481744645985010d3A44", decimals: 18 },
      GLAD: { address: "0xD390D607511b6a08EEdA047d9475a4ff97E3718D", decimals: 18 },
      GLADSHARE: { address: "0x585A041940a956a74Df90a5152234CBB87c89cf2", decimals: 18 },
      "GLAD-AVAX LP": { address: "0x2ae6110F2ba8f8Cf9eC95AB555a08825724CDA26", decimals: 18 },
      "GLADSHARE-AVAX LP": { address: "0xBfb76C873131324eDC322838A8266958b7625413", decimals: 18 },
      "GLAD-GLADSHARE LP": { address: "0x72e3440b3694fD73217CE6fcd435bA781635A623", decimals: 18 },
      SIFU: { address: "0x735EE82bcf53380cC6f82Ed963b687818a81B26e", decimals: 18 },
      ASTRO: { address: "0x6d2f5dBf3a7396FCe32CfE406Aef7a8AFF812Fbb", decimals: 18 },
      GAME: { address: "0x6d2f5dBf3a7396FCe32CfE406Aef7a8AFF812Fbb", decimals: 18 },
      WETH: { address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB", decimals: 18 },
      WBTC: { address: "0x152b9d0FdC40C096757F570A51E494bd4b943E50", decimals: 18, displayName: "BTC.b" },
      SERENE: { address: "0x7f352fA977713019C484C58E9BDfB04910016871", decimals: 18 },
      MAGIK: { address: "0xF7554D17d1c3F09899dCc8B404beCAE6dFA584Fa", decimals: 18 },
    },
    contracts: {
      router: "0xFD292cf9dEaECBc1c6742Ea6FABcb64c045e8fb0",
      zapper: "0xC4c3949534c272305Cbd926ed6E7F7BB48751Ff5",
      addLp: "",
      whiteList: "0x81EA1f3AB6fA18e9c774a959f74B1b1f91144431",
      redeemer: "0x05662c019BaE6414C959fC750fE74B2Fc91D45C9",
      arena: "0x92Adf83e4a1D4A4Ed5be5773506065f32233dB7D",
      treasury: "0x953c3228f08833FCF4dcCfF1eA1D6B9Ab979C7c0",
      boardroom: "0x641e8c73654B761F4613C5726981F2E78B2DE630",
    },
    koc: {
      AVAX: "0xe577F30CccB54F74Bce4ae7628338B261d8DbD6F",
      USDC: "0xD028d47Cb08CEe202FcbD5dE59d4F6A206Fff5c7",
      STOMB: "0x7CDc390c552A58Bf6FF170c11579b8c39657b762",
      SLOT: "0x7d3ACe9F0269fDf37322807455E1b9c517f99487",
      GRAVE: "0x64B5818eC43A144aA3b356ae6F095aF54cfD10f4",
      GSHARE: "0xDC04D4d628f6E9Cfd58495B5fB56c15Aa5cDE9Ba",
      ZOMBIE: "0xb102cb2f270a2C029AEaF4C141e1e27A99FF3b50",
      ZSHARE: "0x86b31B626E9DAB557C0AF203A81CbDbD18F8C882",
      WLRS: "0xDB65aB11D8820C1dF85215D1D6dbEE22f3c973DD",
      WSHARE: "0x7ef1F9ADe3653eab169F283A45CCA7095Fc2113e",
      GRAPE: "0x9559b6887EF139B9f7b84c70aEc1Bea2Be2a8b8f",
      WINE: "0x02Bd8B43Ed266dbA71D7EF2A31895f353C60B588",
      GLAD: "",
      GLADSHARE: "",
      ASTRO: "0xa2a36D2995D9f64Ac2dC7C6B371663751416130A",
      GAME: "0xa2a36D2995D9f64Ac2dC7C6B371663751416130A",
    },
    genesisPools: {
      whiteList: "0x81EA1f3AB6fA18e9c774a959f74B1b1f91144431",
      genesisPool: "0x87940ee5808D15E97c0eEfBAF13Af665281A2190",
      genesisPoolWl: "0xBF5c2D120dF4d7Af5b3016A6bf85FF387b825b9C",
    },
    graphUrls: {
      farm: "https://api.thegraph.com/subgraphs/name/0xm00neth/gladiator-subgraph",
      mint: "https://api.thegraph.com/subgraphs/name/def1dec0ded-gladworks/gladiator-finance-beta",
    },
    preyPredator: "0x00E3D576BB866361CCD7d9B35A0098Efa8F91Ef5",
    preyPredatorGen0Mint: "",
    NFTMintRegister: "",
    NFTPools: {
      initial: "0x4661Adf94016b48C3A37ac1932fDbE14E01eF155",
      NFTPool: "0x4d9D766A380D86eCC4f16377e1bFE795A4B6a6Ab",
      underpeg: "",
    },
    NFTTrainer: "",
    evolution: "",
  },
  production: {
    name: 'production',
    chainId: ChainId.AVALANCHE,
    tokens: {
      AVAX: { address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', decimals: 18 },
      WAVAX: { address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', decimals: 18 },
      USDC: { address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', decimals: 6 },
      kocUSDC: { address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', decimals: 6 },
      STOMB: { address: "0x9e6832D13b29d0B1C1c3465242681039b31C7a05", decimals: 18 },
      SLOT: { address: "0x924157B5dbB387A823719916B25256410a4Ad470", decimals: 18 },
      GRAVE: { address: "0x3700a92dd231F0CaC37D31dBcF4c0f5cCb1db6Ca", decimals: 18 },
      GSHARE: { address: "0xFfE04Bf98C7111360Bf7A6c56b343915543cD941", decimals: 18 },
      ZOMBIE: { address: "0x431bDC9975D570da5eD69C4E97e27114BCd55a86", decimals: 18 },
      ZSHARE: { address: "0xF05e236A139CB19851cD5568A85094D6EE331fAc", decimals: 18 },
      WLRS: { address: "0x395908aeb53d33A9B8ac35e148E9805D34A555D3", decimals: 18 },
      WSHARE: { address: "0xe6d1aFea0B76C8f51024683DD27FA446dDAF34B6", decimals: 18 },
      GRAPE: { address: "0x5541D83EFaD1f281571B343977648B75d95cdAC2", decimals: 18 },
      WINE: { address: "0xC55036B5348CfB45a932481744645985010d3A44", decimals: 18 },
      GLAD: { address: "0xA7c43db4c0F6b59EC3BA65e256025721871AbA7D", decimals: 18 },
      GLADSHARE: { address: "0x8605D0230D5dF8E2A20d8e50F9460E42B4657Cbb", decimals: 18 },
      "GLAD-AVAX LP": { address: "0x36e66BC3C6F4Ccaed436EDd42D9053AF276b18CA", decimals: 18 },
      "GLADSHARE-AVAX LP": { address: "0x25B3B5843ccAa873b0e2d4Bb7Ca8cBF60b83AB81", decimals: 18 },
      "GLAD-GLADSHARE LP": { address: "0x89D206dba3139d3a3D47c3c5f33522E754fF1EEb", decimals: 18 },
      SIFU: { address: "", decimals: 18 },
      ASTRO: { address: "0x6d2f5dBf3a7396FCe32CfE406Aef7a8AFF812Fbb", decimals: 18 },
      GAME: { address: "0x55915FD5433193a082434A280e7A460A3d529d2f", decimals: 18 },
      WETH: { address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB", decimals: 18 },
      WBTC: { address: "0x152b9d0FdC40C096757F570A51E494bd4b943E50", decimals: 8, displayName: "BTC.b" },
      SERENE: { address: "0x7f352fA977713019C484C58E9BDfB04910016871", decimals: 18 },
      MAGIK: { address: "0xF7554D17d1c3F09899dCc8B404beCAE6dFA584Fa", decimals: 18 },
    },
    contracts: {
      router: "0x60aE616a2155Ee3d9A68541Ba4544862310933d4",
      zapper: "0x3864f68Bf3F32Bd3B28979B8540D661DFd152A7C",
      addLp: "0x2c1082D66C6C1a8AE31f6f65CB31Bfad056148de",
      whiteList: "0xB7deD448A165A8117bCf4899c4CFb4a255C0F69b",
      redeemer: "0xeDe98De7707F87c0E8082EACFea813950c6cccF8",
      arena: "0x2DbAE3aB1bf266D94cE2304eEb10098d7dAb4b97",
      treasury: "0x8B56cD8E7342BAFF7ec742227B86b8C45B5548d2",
      boardroom: "0x9573B6E595679941Bb77688fD7e796ad1c35B6F4",
    },
    koc: {
      AVAX: "0x07d650EeFf4424B7C72359b4B89CeE0a0e0eD099",
      USDC: "0x044430507904d9B05eE7C6eE1fC7C739950F55D1",
      STOMB: "0x7CDc390c552A58Bf6FF170c11579b8c39657b762",
      SLOT: "0x7d3ACe9F0269fDf37322807455E1b9c517f99487",
      GRAVE: "0xA0224171d185bAFD583a6ecd642B9915951cF068",
      GSHARE: "0xA90148b17ED0fddE5a79f2855ab1Dfebb1F0F7eb",
      ZOMBIE: "0xdB664527b5e53161B4EE255d8Faed7cB6a136b3C",
      ZSHARE: "0x00aDC7f70d86AB6142621BC38d1BFe3eF9108AEF",
      WLRS: "0x583e0717af8ad2f749a48cbe5294c3a2180ffa13",
      WSHARE: "0x7ef1F9ADe3653eab169F283A45CCA7095Fc2113e",
      GRAPE: "0x533b61CA482Ed8521022dCC32666151300236E5b",
      WINE: "0x02Bd8B43Ed266dbA71D7EF2A31895f353C60B588",
      GLAD: "",
      GLADSHARE: "",
      ASTRO: "0xa2a36D2995D9f64Ac2dC7C6B371663751416130A",
      GAME: "0xFc2Ca2E429fF450A77DE0a212b2551865f9d22b5",
    },
    genesisPools: {
      whiteList: "0xB7deD448A165A8117bCf4899c4CFb4a255C0F69b",
      genesisPool: "0x9Bca4DF7bb20Dbd5E7E4226796c647432cE38587",
      genesisPoolWl: "0x5aa0dFAEe897B41C2C3109Ea165BE99B66af0197",
    },
    graphUrls: {
      farm: "https://api.thegraph.com/subgraphs/name/0xm00neth/gladiator-subgraph",
      mint: "https://api.thegraph.com/subgraphs/name/def1dec0ded-gladworks/gladiator-finance",
    },
    preyPredator: "0x68D2E2Db5924B44ef851411825FD57D7EBfaC5A7",
    preyPredatorGen0Mint: "0x8B56cD8E7342BAFF7ec742227B86b8C45B5548d2",
    NFTMintRegister: "0xcFEa33ff17bb28F5831116ff839355F00C74ccAd",
    NFTPools: {
      initial: "0x26942eB6d555E5Bd72C3Fb51fB6F761224581a2E",
      NFTPool: "0xbeF22A47e2aCB0D3c1dF1b3958863ECd92ca5B62",
      underpeg: "0xA09D9c10AD967786A48ba822155b441A23FF48e0",
    },
    NFTTrainer: "0x699066AcD18eE58D7E67E89BD51c839f112b6364",
    evolution: "0x09B092A973bAcb83Ec25f2f5cB1D719611E78C46",
  },
};

export interface BankInfo {
  depositTokenName: Symbol;
  poolId: number;
  earnTokenName: Symbol;
  isGenesisPool?: boolean;
  logo?: string;
  logoClassName?: string;
  tradeLink?: string;
  disabled?: boolean;
}

export interface Bank extends BankInfo {
  poolName: string;
  TVL: number;
  dailyAPR: number;
  yearlyAPR: number;
  staked: boolean;
}

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  "GLAD-AVAX LP": {
    poolId: 0,
    depositTokenName: Symbol.TOMB_AVAX_LP,
    earnTokenName: Symbol.TSHARE,
    tradeLink: `https://traderjoexyz.com/pool/AVAX/0xA7c43db4c0F6b59EC3BA65e256025721871AbA7D#/`,
  },
  "GLADSHARE-AVAX LP": {
    poolId: 1,
    depositTokenName: Symbol.TSHARE_AVAX_LP,
    earnTokenName: Symbol.TSHARE,
    tradeLink: `https://traderjoexyz.com/pool/AVAX/0x25B3B5843ccAa873b0e2d4Bb7Ca8cBF60b83AB81#/`,
  },
  "GLAD-GLADSHARE LP": {
    poolId: 2,
    depositTokenName: Symbol.TOMB_TSHARE_LP,
    earnTokenName: Symbol.TSHARE,
    tradeLink: `https://traderjoexyz.com/pool/AVAX/0x89D206dba3139d3a3D47c3c5f33522E754fF1EEb#/`,
  },
};

export const genesisPoolsWl: { [contractName: string]: BankInfo } = {
  "GLAD-AVAX LP": {
    poolId: 0,
    depositTokenName: Symbol.TOMB_AVAX_LP,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: glad,
    tradeLink: `https://traderjoexyz.com/pool/AVAX/${configurations[selectedConf]?.tokens[Symbol.TOMB]?.address || ""}`
  },
  "GLADSHARE-AVAX LP": {
    poolId: 1,
    depositTokenName: Symbol.TSHARE_AVAX_LP,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: gladshare,
    tradeLink: `https://traderjoexyz.com/pool/AVAX/${configurations[selectedConf]?.tokens[Symbol.TSHARE]?.address || ""}`
  },
  WAVAX: {
    poolId: 2,
    depositTokenName: Symbol.WAVAX,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: avax,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.WAVAX]?.address || ""}`
  },
  USDC: {
    poolId: 3,
    depositTokenName: Symbol.USDC,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: usdc,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.USDC]?.address || ""}`
  },
  GLAD: {
    poolId: 4,
    depositTokenName: Symbol.TOMB,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: glad,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.TOMB]?.address || ""}`
  },
  WETH: {
    poolId: 5,
    depositTokenName: Symbol.WETH,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: eth,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.WETH]?.address || ""}`
  },
  WBTC: {
    poolId: 6,
    depositTokenName: Symbol.WBTC,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: btc,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.WBTC]?.address || ""}`
  },
};

export const genesisPools: { [contractName: string]: BankInfo } = {
  "GLAD-AVAX LP": genesisPoolsWl["GLAD-AVAX LP"],
  "GLADSHARE-AVAX LP": genesisPoolsWl["GLADSHARE-AVAX LP"],
  WAVAX: genesisPoolsWl["WAVAX"],
  USDC: genesisPoolsWl["USDC"],
  WETH: {
    poolId: 4,
    depositTokenName: Symbol.WETH,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: eth,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.WETH]?.address || ""}`
  },
  WBTC: {
    poolId: 5,
    depositTokenName: Symbol.WBTC,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: btc,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.WBTC]?.address || ""}`
  },
  SERENE: {
    poolId: 6,
    depositTokenName: Symbol.SERENE,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: serene,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.SERENE]?.address || ""}`
  },
  GRAPE: {
    poolId: 7,
    depositTokenName: Symbol.GRAPE,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: grape,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.GRAPE]?.address || ""}`
  },
  WINE: {
    poolId: 8,
    depositTokenName: Symbol.WINE,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: wine,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.WINE]?.address || ""}`
  },
  GRAVE: {
    poolId: 9,
    depositTokenName: Symbol.GRAVE,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: grave,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.GRAVE]?.address || ""}`
  },
  GSHARE: {
    poolId: 10,
    depositTokenName: Symbol.GSHARE,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: gshare,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.GSHARE]?.address || ""}`
  },
  ZOMBIE: {
    poolId: 11,
    depositTokenName: Symbol.ZOMBIE,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: zombie,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.ZOMBIE]?.address || ""}`
  },
  ZSHARE: {
    poolId: 12,
    depositTokenName: Symbol.ZSHARE,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: zshare,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.ZSHARE]?.address || ""}`
  },
  WLRS: {
    poolId: 13,
    depositTokenName: Symbol.WLRS,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: wlrs,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.WLRS]?.address || ""}`
  },
  WSHARE: {
    poolId: 14,
    depositTokenName: Symbol.WSHARE,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: wshare,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.WSHARE]?.address || ""}`
  },
  ASTRO: {
    poolId: 15,
    depositTokenName: Symbol.ASTRO,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: astro,
    logoClassName: "rounded-full",
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.ASTRO]?.address || ""}`
  },
  MAGIK: {
    poolId: 16,
    depositTokenName: Symbol.MAGIK,
    earnTokenName: Symbol.TOMB,
    isGenesisPool: true,
    logo: magik,
    tradeLink: `https://traderjoexyz.com/trade?outputCurrency=${configurations[selectedConf]?.tokens[Symbol.MAGIK]?.address || ""}`
  },
};

export default configurations[selectedConf];

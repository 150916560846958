import { InMemoryCache, ApolloClient } from '@apollo/client'
import { useMemo } from 'react';

function createApolloClient(graphUrl: string, ssrMode: boolean) {
  return new ApolloClient({
    ssrMode,
    uri: graphUrl,
    cache: new InMemoryCache(),
  });
}

export function useApollo(graphUrl: string) {
  return useMemo(() => createApolloClient(graphUrl, false), []);
}
import React from "react";

export enum Size {
    sm = "sm",
    lg = "lg",
}

export enum BtnType {
    filled = "filled",
    outlined = "outlined",
    linkLike = "linkLike",
}

export enum Position {
    top = "top",
    right = "right",
    bottom = "bottom",
    left = "left",
}

export enum Symbol {
    TOMB = "GLAD",
    TSHARE = "GLADSHARE",
    TOMB_AVAX_LP = 'GLAD-AVAX LP',
    TOMB_TSHARE_LP = 'GLAD-GLADSHARE LP',
    TSHARE_AVAX_LP = 'GLADSHARE-AVAX LP',
    AVAX = "AVAX",
    WAVAX = "WAVAX",
    USDC = "USDC",
    USDT = "USDT",
    STOMB = "STOMB",
    SLOT = "SLOT",
    GRAVE = "GRAVE",
    GSHARE = "GSHARE",
    ZOMBIE = "ZOMBIE",
    ZSHARE = "ZSHARE",
    WLRS = "WLRS",
    WSHARE = "WSHARE",
    GRAPE = "GRAPE",
    WINE = "WINE",
    SIFU = "SIFU",
    ASTRO = "ASTRO",
    GAME = "GAME",
    SERENE = "SERENE",
    WETH = "WETH",
    WBTC = "WBTC",
    MAGIK = "MAGIK",
}

export interface Link {
    name: string;
    href: string;
    component?: React.ReactNode;
    target?: string;
    rel?: string;
    disabled?: boolean;
    highlighted?: boolean;
    noComponent?: boolean;
}

export interface DynamicObject<T> {
    [key: string]: T;
}

export interface Roman {
    id: string;
    alphaIndex?: string;
    isPrey: boolean;
    tokenURI: string;
    selected?: boolean;
    generation: string;
    lastActivity?: number;
    underpeg_hasBeenStaked?: boolean;
    stakedAt?: number;
    premium?: number;
    mintPrice?: number;
    claimed?: number;
    agility: number;
    charisma: number;
    damage: number;
    defense: number;
    dexterity: number;
    health: number;
    intelligence: number;
    luck: number;
    strength: number;
}

export interface TimeDisplay {
    time: string;
    name: string;
}
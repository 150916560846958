import { lazy, Suspense, useRef } from "react";
import { Toaster } from "react-hot-toast";
import { ApolloProvider } from '@apollo/client'
import Loading from "./components/Loading/Loading";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ToTop from "./components/ToTop";
import { Link } from "./types";
import { useApollo } from "./hooks/useApolloClient";
import config from "./config";

const LandingPage = lazy(() => import("./pages/LandingPage/LandingPage"));
const FarmPage = lazy(() => import("./pages/FarmPage"));
const NFTGamePage = lazy(() => import("./pages/NFTGamePage"));
const CatacombsPage = lazy(() => import("./pages/CatacombsPage"));
const ArmyPage = lazy(() => import("./pages/ArmyPage/ArmyPage"));
const NoRoutePage = lazy(() => import("./pages/NoRoutePage"));

function App() {
  // Start new Apollo Client
  const apolloClientFarm = useApollo(config.graphUrls.farm);
  const apolloClientMint = useApollo(config.graphUrls.mint);

  const refHeader = useRef();

  const links: Link[] = [
    {
      name: "Home",
      href: "/",
      component: <LandingPage refHeader={refHeader} />,
    },
    {
      name: "NFT Game",
      href: "/nft-game",
      component: <ApolloProvider client={apolloClientMint}><NFTGamePage /></ApolloProvider>,
      highlighted: true,
    },
    {
      name: "Arena",
      href: "/arena",
      component: <ApolloProvider client={apolloClientFarm}><FarmPage isGenesisPool={false} /></ApolloProvider>,
    },
    { name: "Catacombs", href: "/catacombs", component: <CatacombsPage /> },
    {
      name: "KoC",
      href: "https://koc.money",
      target: "_blank",
      rel: "noopener noreferrer",
      noComponent: true,
    },
    {
      name: "Genesis",
      href: "/genesis",
      component: <ApolloProvider client={apolloClientFarm}><FarmPage isGenesisPool={true} /></ApolloProvider>,
    },
    // { name: "Army", href: "/army", component: <ArmyPage /> },
    {
      name: "Articles",
      href: "https://medium.com/@GladiatorDeFi",
      target: "_blank",
      rel: "noopener noreferrer",
      noComponent: true,
    },
    {
      name: "Docs",
      href:
        "https://gladiator-protocol.gitbook.io/gladiator-protocol/T8QENNDx7YQm9CkauSpA/",
      target: "_blank",
      rel: "noopener noreferrer",
      noComponent: true,
    },
  ];

  const Fallback = (): JSX.Element => {
    return (
      <div className="container mx-auto px-5 flex justify-center bg-lightblue">
        <Loading />
      </div>
    );
  };

  return (
    <Router>
      <div>
        <Toaster position="top-right" />
      </div>
      <Header links={links} refHeader={refHeader} />
      <Routes>
        {links
          .map((link, i) => {
            return (
              <Route
                key={i.toString()}
                path={link.href}
                element={
                  <Suspense fallback={<Fallback />}>{link.component}</Suspense>
                }
              />
            );
          })}
        <Route
          path="*"
          element={
            <Suspense fallback={<Fallback />}>
              <NoRoutePage />
            </Suspense>
          }
        />
      </Routes>
      <Footer links={links} />
      <ToTop />
    </Router>
  );
}

export default App;
